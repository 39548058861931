<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title">抖店订单
      <!-- <div class="hk_page_title_right">
        <el-button type="primary" size="small" plain disabled >导出</el-button>
      </div> -->
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">

          <div class="hk_search_box_item">
            订单状态：
            <el-select v-model="searchOrderStatus" size="small" clearable placeholder="订单状态">
              <el-option label="全部" value="">全部</el-option>
              <el-option label="已完成" value="FINISH">已完成</el-option>
              <el-option label="已退费" value="REFUND">已退费</el-option>
              <el-option label="退费申请" value="REFUND_APPLY">退费申请</el-option>
              <el-option label="退费失败" value="REFUND_FAIL">退费失败</el-option>
            </el-select>

          </div>

          <div class="hk_search_box_item">
            付款时间：
            <el-date-picker v-model="searchTime" size="small" class="ipt-date-suffix" type="daterange" clearable range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchEmployeeFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{ totalNum }}</span> 个订单，共计收款金额<span class="hk-font-highlight">￥{{ totalFeeMoney }}</span>
        </div>

        <div class="hk_custom_table">
          <el-table ref="myTable" :data="tableData">
            <el-table-column label="姓名" width="120px" fixed>
              <template slot-scope="scope">
                <span>{{ scope.row.stuName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="手机号" width="120px">
              <template slot-scope="scope">
                <span>{{scope.row.phone||'--'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="虚拟手机号" width="160px">
              <template slot-scope="scope">
                <span>{{scope.row.expandInfo.virtualPhone||'--'}}</span> <br>
                <span v-if="scope.row.virtualPhoneLeftDay">剩余有效时间：{{scope.row.virtualPhoneLeftDay}}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品名" width="450px">
              <template slot-scope="scope">
                <div class="red-product-show">
                  <div class="rps-item" v-for="(order,index) in scope.row.orderList" :key="order.id+'_'+index">
                    <img :src="order.productImg" alt="" class="rps-img">
                    <div class="rps-item-info">
                      <div class="info-name">{{order.productName}}</div>
                      <div class="info-txt">规格：{{order.expandInfo.spec || '--'}}
                        <div class="txt-right">X{{order.expandInfo.itemNum}}</div>
                      </div>
                      <div class="info-txt">条形码：{{order.barCode || '--'}}</div>
                      <div class="info-txt">商家编码：{{order.erpCode || '--'}}</div>
                    </div>
                  </div>

                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="规格" width="180px">
              <template slot-scope="scope">
                <span>{{scope.row.expandInfo.spec}}</span>
              </template>
            </el-table-column>
            <el-table-column label="件数" min-width="100px">
              <template slot-scope="scope">
                <span>{{scope.row.expandInfo.itemNum}}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="付款时间" width="160px">
              <template slot-scope="scope">
                <span>{{scope.row.payTime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="实付金额" :min-width="100">
              <template slot-scope="scope">
                <span>{{scope.row.amount/ 100}}</span>
              </template>
            </el-table-column>

            <el-table-column label="地址" width="200px">
              <template slot-scope="scope">
                <span> {{ scope.row.receiveDetailAddress || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" width="120px">
              <template slot-scope="scope">
                {{scope.row.orderStatus}}
                <!-- 
                <el-tooltip class="item" effect="dark" :content="scope.row.refundErrMsg" placement="top-end" v-if="scope.row.orderStatus=='REFUND_FAIL'">
                  <span class="hk-font-red">{{scope.row.orderStatusStr}}</span>
                </el-tooltip>
                <span class="hk-font-highlight" v-else-if="scope.row.orderStatus=='REFUND' || scope.row.orderStatus=='REFUND_APPLY'">{{scope.row.orderStatusStr}}</span>
                <span v-else>{{scope.row.orderStatusStr}}</span> -->
              </template>
            </el-table-column>

            <div slot="empty">
              <p style="text-align:left;">没有订单信息哦！</p>
            </div>
          </el-table>
          <div class="hk_pagination" v-if="totalNum>0">
            <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
            </el-pagination>
            <!-- total,prev, pager, next -->
          </div>
        </div>
        <div class="hk_custom_scroll">
          <div ref="hkCustomScroll" class="hk-scroll" @scroll="handleScrollTop">
            <div class="hk-scroll-content" :style="'width:'+tableWidth+'px'"></div>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      tableWidth: 0, //table内容的实际宽度，赋值给自定义滚动条

      searchOrderStatus: '', //搜索条件--订单状态

      searchTime: [], //搜索条件--付款开始时间-结束时间
      tableData: [], //列表中显示的数据(搜索后,只显示符合条件的数据)
      pageSize: 50, //每页50条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      totalFeeMoney: 0, //订单总额

    }
  },
  mounted() {
    this.getDataListFun()
    this.getTotalFee()
  },
  methods: {
    //自定义滚动条的事件
    handleScrollTop(e) {
      let that = this;
      let tmpLeft = e.target.scrollLeft
      if (that.$refs["myTable"]) {
        that.tableDom = that.$refs["myTable"].bodyWrapper
        that.tableDom.scrollTo(tmpLeft, 0)

      }
    },
    searchEmployeeFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getDataListFun()
      this.getTotalFee()
    },
    //获取数据列表
    getDataListFun() {
      let that = this;
      let param = {
        pn: that.curPage,
        ps: that.pageSize,
        orderType: 'DOU_DIAN'

      };

      if (that.searchOrderStatus) { //订单状态
        param.orderStatus = that.searchOrderStatus
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }

      that.tableData = [] //先将数据清空，避免视图不及时更新
      that.request({
        method: 'post',
        url: '/manage/order/listParentOrder',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.tableData = data.list || [];
            that.totalNum = Number(data.total);

            setTimeout(() => {
              if (that.$refs["myTable"]) {
                that.tableWidth = that.$refs["myTable"].bodyWrapper.scrollWidth + 50 //没有fixed或者fixed=right,发现宽度少了
                that.$refs["myTable"].doLayout()
                that.tableDom = that.$refs["myTable"].bodyWrapper
                that.tableDom.addEventListener('scroll', () => {
                  let scrollLeft = that.tableDom.scrollLeft
                  // console.log('table scroll----', scrollLeft)
                  that.$refs.hkCustomScroll.scrollTo(scrollLeft, 0)
                })
              }
            }, 300)
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单列表失败。')
          console.log(error, '-----fenye 获取订单失败')
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getDataListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getDataListFun();
    },

    //获取订单总收款金额
    getTotalFee() {
      let that = this;
      let param = {};

      if (that.searchOrderStatus) { //订单状态
        param.orderStatus = that.searchOrderStatus
      }

      if (that.searchTime && that.searchTime[0]) {
        param.queryStartTime = that.searchTime[0].getTime()
        param.queryEndTime = that.searchTime[1].getTime();
      }
      param.orderType = 'DOU_DIAN';
      that.request({
        method: 'post',
        url: '/manage/order/countAmount',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            that.totalFeeMoney = (result.result || 0) / 100
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取订单totalfee失败。')
          console.log(error, '-----totalfee失败')
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
// 表格中的商品信息
.red-product-show {
  .rps-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }

    .rps-img {
      width: 91px;
      height: 91px;
    }
    .rps-item-info {
      flex: 1;
      padding-left: 8px;
      font-family: PingFangSC, PingFang SC;
      .info-title {
        font-weight: 600;
        font-size: 14px;
        color: #000;
        line-height: 22px;
        padding-bottom: 5px;
      }
      .info-txt {
        font-weight: 400;
        font-size: 12px;
        color: #606060;
        line-height: 20px;
        display: flex;

        .txt-right {
          width: 50px;
          padding-left: 20px;
          font-size: 12px;
          color: #999999;
          letter-spacing: 2px;
        }
      }
    }
  }
}

//将el-table的横向滚动条给隐藏,只能写在每个页面，写在公共里面无效
.hk_custom_table {
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
}
</style>